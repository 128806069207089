.selected-work-page {

  &__hero {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__hero-bg {
    height: 100vh;

    .image-preloader {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--volta {
      background: #0c0c0d!important;

      @media (max-width: 768px) {
        img {
          width: 100%!important;
          height: 100%!important;
          object-fit: cover!important;
        }
      }
    }

    &--game-out {
      background: linear-gradient(184deg, #01121e, #001a1e) !important;

      .image-preloader {
        justify-content: flex-end;

        @media (max-width: 768px) {
          img {
            width: 100%!important;
            height: 100%!important;
            object-fit: cover!important;
          }
        }
      }
    }

    &--dashboard {
      background-color: #0d0f1a;

      .image-preloader {
        justify-content: flex-end;
        align-items: flex-end;

        @media (max-width: 768px) {
          img {
            height: auto!important;
          }
        }
      }
    }

    &--react-email {
      .image-preloader {
        background: #0e161f;
      }
    }

    &--react-dashboard {
      .image-preloader {
        background: linear-gradient(220deg, #2b2f3f 100%, #0d0e11 0%);
      }
    }

    &--oracle {
      background: linear-gradient(45deg, #f18528, #f50f1a)!important;
    }

    &--not-cover {
      background: #0a1b2f;

      img {
        height: 100% !important;
      }
    }

    &--game-out {
      @media (max-width: 576px) {
        img {
          object-fit: cover!important;
        }
      }
    }

    &--charts-controls {
      .image-preloader {
        justify-content: flex-end;
        align-items: flex-end;
        background: radial-gradient(circle at 50% 0, rgba(134, 35, 227, 0.5), rgba(0, 0, 0, 0.5) 81%), linear-gradient(to bottom, #061221, #061221);

        & > img {
          @media (max-width: 991px) {
            height: auto!important;
          }
        }
      }
    }

    &--westworld {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(201deg, #046562 35%, #1d7bba 65%, #140937 100%);;

      @media (max-width: 768px) {
        img {
          width: 100%!important;
          height: 100%!important;
          object-fit: cover!important;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: center;

      @media (max-width: 991px) {
        object-fit: contain;
      }
    }

    &--not-cover {
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &--apple {
      background-color: #1d1473;

      .image-preloader {
        background: url('/img/apple-bg@3x.png') no-repeat top/cover;
      }

      img {
        max-height: 80%;
        object-fit: contain;
      }
    }

    &--sci-fi {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--sci-fi-2023 {
      background: conic-gradient(from -180deg, rgba(5, 0, 0, 0.94), rgba(5, 0, 0, 0.63) 0.35turn, rgba(5, 0, 0, 0) 0.55turn, rgba(5, 0, 0, 0.96) 0.91turn), url("/img/sky-image.jpg") no-repeat center/cover;
      
      .image-preloader {
        max-width: 1240px;
        padding: 0 20px;
        margin: 0 auto;
      }
      // background: conic-gradient(from 90deg at 50% 50%, #FF0037, rgba(255,144,168,1))
      img {
        width: auto;
        height: auto!important;
        max-width: 100%;
        object-fit: unset;
        
        @media (max-width: 768px) {
          transform: scale(1.5) translateX(97px);
        }
      }
    }

    &--ylopo {
      background: linear-gradient(223deg, #1a1e36 73%, #0c0c10 100%);

      .image-preloader {
        align-items: flex-end;

        img {
          max-height: 90%;
        }
      }
    }
  }
}
