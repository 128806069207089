/** @format */
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");

.container {
  box-sizing: border-box;
  background-color: #0a062d;
  color: #ffffff;
  width: 100%;
  font-family: "Rubik", sans-serif;
  overflow-x: hidden !important;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  padding-top: 115px;
}

.homeSection {
  position: relative;
  width: 100%;
  height: fit-content;
  /* background: url("./assets/bg.png");
  background-position: center center;
  background-size: cover; */
  padding-top: 40px;
}

.homeBackground1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: fit-content; */
  object-fit: contain;
  mix-blend-mode: overlay;
  z-index: -10;
}

.homeBackground2 {
  display: none;
}

.header {
  position: relative;
  align-items: center;
  justify-content: space-between;
  /* background-color: red; */
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 980px;
  padding: 40px 40px;
}

.header .logo1 {
  height: 92px;
  object-fit: contain;
  width: fit-content;
}

.header .logo2 {
  height: 92px;
  object-fit: contain;
  width: fit-content;
}

.header .logo3 {
  height: 68px;
  object-fit: contain;
  width: fit-content;
}

.homeSectionContent {
  position: relative;
  max-width: 1300px;
  margin: 60px auto 80px auto;
  padding: 0 40px;
  padding-bottom: 0;
  display: flex;
  gap: 0px 30px;
}

.homeSectionContent .chartImg {
  width: 40%;
  object-fit: contain;
  height: fit-content;
}

.homeSectionContent .homeSectionContentInner {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 25px 0px;
}

.homeSectionContentInner div {
  display: flex;
  flex-direction: column;
  gap: 10px 0px;
}

.homeSectionContentInner div h3 {
  font-weight: 600;
  font-size: 24px;
}

.homeSectionContentInner div p {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
}

.homeSectionCards {
  position: relative;
  margin: 60px auto;
  padding: 0px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 1280px;
  height: fit-content;
  gap: 15px;
  width: 100%;
}

.homeSectionCards .homeSectionCard {
  grid-column: span 1 / span 1;
  height: 150px;
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(260.96deg, #130c4f 43.14%, #160d6b 63.41%);
  box-shadow: -2px 4px 4px 0px #00000040 inset;
}

.homeSectionCard .cardTop1 {
  background-color: #0b30b4;
  position: relative;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 16px;
}

.homeSectionCard .cardTop2 {
  background-color: #00ffb2;
  position: relative;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 16px;
}

.homeSectionCard .cardTop3 {
  background-color: #a02e0a;
  position: relative;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 16px;
}

.homeSectionCard .cardTop4 {
  background-color: #7b0499;
  position: relative;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 16px;
}

.homeSectionCard .cardContent {
  padding: 15px 10px;
  flex-direction: column;
  gap: 5px 0px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.homeSectionCard .cardContent h4 {
  font-weight: 500;
  font-size: 18px;
}

.homeSectionCard .cardContent .span1 {
  font-weight: 500;
  margin: 5px 0px;
  font-size: 18px;
  color: #4c73ff;
}

.homeSectionCard .cardContent .span2 {
  font-weight: 500;
  margin: 5px 0px;
  font-size: 18px;
  color: #00ffb2;
}

.homeSectionCard .cardContent p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  color: #afafaf;
}

.homeSectionCard ol {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  gap: 5px 0px;
}

.homeSectionCard .whiteP {
  font-weight: 500;
  color: white;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 5px;
}

.radarRow {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  margin: 60px auto;
  padding: 0px 20px;
  font-family: "Orbitron";
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */

  height: fit-content;
  gap: 10px 40px;
  width: 100%;
}

.radarRow .radar {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px 0px;
  align-items: center;
  justify-content: center;
}

.radarRow .radar img {
  width: 120px;
  object-fit: contain;
  height: 120px;
}

.radarRow .radar p {
  color: #9fa5cc;
  text-align: center;
  font-size: 24px;
}

.contentSection {
  position: relative;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
}

.contentSection .content {
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  gap: 20px 0px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.contentSection .content .imgs {
  display: flex;
  gap: 10px 30px;
  align-items: center;
  justify-content: center;
}

.contentSection .content .imgs .imgs1 {
  height: 65px;
  object-fit: contain;
  width: fit-content;
}

.contentSection .content .imgs .imgs2 {
  height: 37px;
  object-fit: contain;
  width: fit-content;
}

.contentSection .content p {
  max-width: 956px;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  font-size: 24px;
}

.contentSection .content button {
  border: none;
  background: linear-gradient(92.37deg, #1e3f96 22.2%, #132961 67.39%);
  font-weight: 500;
  font-size: 16px;
  color: white;
  box-shadow: none;
  outline: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 30px;
}

.contentSection .content2 {
  gap: 40px 0px;
  margin: 20px 0px;
}

.contentSection .content2 img {
  height: 69px;
  width: 90%;
}

.contentSection .content2 p {
  max-width: 820px;
}

.contentSection>img {
  width: 100%;
  object-fit: contain;
  height: fit-content;
  margin: 20px 0px;
}

.contentSection .imageGrid {
  width: 100%;
  margin: 20px 0px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
}

.imageGrid .img1 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img2 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img3 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img4 {
  grid-column: span 1 / span 1;
  grid-row: span 3 / span 3;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img5 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img6 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img7 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img8 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img9 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.imageGrid .img10 {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  width: 100%;
  object-fit: contain;
}

.contentSection .paragraph {
  /* margin: 0;
  gap: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px 0px;
  margin: 20px 0px;
}

.contentSection .paragraph p {
  max-width: 820px;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  font-size: 24px;
}

.contentSection .paragraph h4 {
  max-width: 820px;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 600;
}

.contentSection .halfImg {
  width: 50%;
}

.footer {
  max-width: 820px;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 40px 0px;
  padding: 60px 30px;
}

.footer img {
  height: 65px;
  object-fit: contain;

  width: fit-content;
}

.footer div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px 0px;
  flex-direction: column;
}

.footer h3 {
  font-size: 32px;
  font-weight: 600;
}

.footer p {
  font-size: 24px;
}

.footer a {
  border: none;
  background: linear-gradient(92.37deg, #1e3f96 22.2%, #132961 67.39%);
  font-weight: 500;
  font-size: 16px;
  color: white;
  box-shadow: none;
  outline: none;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 30px;
  text-decoration: none;
}

footer .btns {
  gap: 20px 0px;
}

@media (max-width: 1024px) {
  .homeBackground1 {
    /* width: 100vw;
    height: 100%;
    object-fit: cover;
    object-position: center center; */
  }

  .contentSection .halfImg {
    width: 80%;
  }

  .contentSection {
    padding: 40px 40px;
  }

  .homeSectionCards {
    grid-template-columns: 1fr 1fr;
  }

  .homeSectionCards .homeSectionCard {
    height: 100%;
  }

  .radarRow {
    max-width: 800px;
    gap: 40px 40px;
  }

  .header .logo1,
  .header .logo2 {
    height: 80px;
  }

  .header .logo3 {
    height: 50px;
  }

  .contentSection .content .imgs .imgs1 {
    height: 50px;
    object-fit: contain;
    width: 90%;
  }

  .contentSection .content .imgs .imgs2 {
    height: 28px;
    object-fit: contain;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .homeBackground2 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    /* height: fit-content; */
    object-fit: contain;
    z-index: -10;
  }

  .contentSection {
    padding: 30px 30px;
  }

  .homeSection .header {
    padding: 30px 30px;
  }

  .homeSectionContent {
    padding: 30px 30px;
    flex-direction: column;
    align-items: center;
    gap: 30px 0px;
    justify-content: center;
  }

  .homeSectionCards {
    padding: 30px 30px;
  }

  .radarRow {
    padding: 30px 30px;
  }

  .homeSectionContent .homeSectionContentInner {
    width: 100%;

    /* text-align: center; */
    gap: 30px 0px;
  }

  .homeSectionContent .chartImg {
    width: 80%;
    margin-top: 50px;
  }

  .homeSection {
    padding-top: 60px;
  }

  .header .logo1,
  .header .logo2 {
    height: 60px;
  }

  .header .logo3 {
    height: 40px;
  }

  .radarRow .radar img {
    width: 100px;
  }

  .radarRow .radar p {
    font-size: 20px;
  }

  .radarRow .radar {
    gap: 10px 0px;
  }

  .contentSection .content .imgs {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }

  .contentSection .halfImg {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .footer {
    padding-top: 40px;
  }

  /* .contentSection {
    display: none;
  } */
  .homeSection {
    padding-top: 30px;
  }

  .homeBackground1 {
    display: none;
  }

  .homeSection .header {
    padding: 10px 20px;
  }

  .contentSection {
    padding: 20px 20px;
  }

  .radarRow .radar {
    gap: 5px 0px;
  }

  .radarRow .radar p {
    font-size: 16px;
  }

  .radarRow .radar img {
    width: 80px;
  }

  .homeSection .header {
    padding: 20px 20px;
  }

  .header .logo1,
  .header .logo2 {
    height: 90px;
  }

  .header .logo3 {
    height: 54px;
  }

  .contentSection .imageGrid {
    width: 100%;
    margin: 20px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }

  .homeSectionContent .chartImg {
    width: 100%;
    margin-top: 10px;
  }

  /* .contentSection .content .imgs .imgs1 {
    height: 40px;
    object-fit: contain;
    width: fit-content;
  }
  .contentSection .content .imgs .imgs2 {
    height: 13px;
    object-fit: contain;
    width: fit-content;
  } */
  .header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px 0px;
  }
}

@media (max-width: 550px) {
  .homeSectionCards {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .radarRow {
    gap: 20px 20px;
  }
}

@media (max-width: 450px) {
  .contentSection .imageGrid {
    width: 100%;
    margin: 20px 0px;
    display: grid;

    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
}