@font-face {
  font-family: 'EffraMedium';
  src: local('EffraMedium'), url('/fonts/Effra-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Athelas';
  src: local('Athelas'), url('/fonts/Athelas-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'EffraLight';
  src: local('EffraLight'), url('/fonts/Effra-Light.ttf');
}

@font-face {
  font-family: 'EffraRegular';
  src: local('EffraLight'), url('/fonts/Effra-Regular.ttf');
}

@font-face {
  font-family: 'Effra';
  src: local('Effra'), url('/fonts/Effra-Medium.ttf');
}

.h2 {
  font: 43px/1.2 'IBMPlexSans', sans-serif;
  color: #fff;

  &--thank-you {
    font-family: 'EffraMedium';
    padding-top: 50px;
  }
}