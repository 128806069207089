.viewport {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &--empty-100 {
    height: 100vh;
  }
}


.agency {
  min-height: 100vh;
  padding-bottom: 170px;

  &__bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    height: 150vh;
    background: linear-gradient(176deg, rgba(8, 15, 57, .7) 0, rgba(7, 6, 23, .7) 100%), url("/img/agency-bg-new.png") no-repeat;
    background-size: cover;
    background-position: top;
    background-attachment: fixed;

    @media (max-width: 768px) {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 70px 47px #070619;
    }
  }

  &__hero {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    overflow: hidden;
    //background: linear-gradient(176deg, rgba(8,15,57, .7), rgba(7,6,23, .7) 49%), url("/img/agency-bg.png") no-repeat;

    @media (max-width: 768px) {
      background: url("/img/agency-mobile-bg-3@x.png") no-repeat center/cover;
    }
  }

  &__hero-container {
    max-width: 720px;
    padding: 0 20px;
    margin: 0 auto;
  }

  &__hero-title {
    position: relative;
    font: 52px/64px 'Athelas', sans-serif;
    color: #fff;
    margin-bottom: 77px;

    /* &:before {
      content: '';
      position: absolute;
      width: 260px;
      height: 260px;
      left: -340px;
      top: 50%;
      transform: translateY(-50%);
      background: url('/img/agency-cm.png');
      background-size: contain;
    }*/

    @media (max-width: 991px) {
      text-shadow: 2px 2px 2px #2A2C28;
    }

    @media (max-width: 768px) {
      font-size: 44px;
      line-height: 1.2;
    }
  }

  &__hero-img {
    position: fixed;
    z-index: 10;
    left: 15%;
    top: calc(100vh / 2);
    transform: translateY(-60%);

    @media (max-width: 1600px) {
      left: 10%;
    }

    @media (max-width: 1300px) {
      left: 5%;
    }

    @media (max-width: 991px) {
      display: none;
    }

    img {
      opacity: .3;
    }
  }

  .btn__container--coming-soon {
    text-align: center;
  }

  &__container {
    max-width: 1470px;
    padding: 0 130px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 0 60px;
    }

    @media (max-width: 576px) {
      padding: 0 20px;
    }

    &:not(:last-of-type) {
      margin-bottom: 57px;
    }

    &--column {
      display: grid;
      grid-template-columns: 320px 1fr;

      @media (max-width: 1200px) {
        grid-template-columns: 230px 1fr;
      }

      @media (max-width: 991px) {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
      }
    }
  }

  &__content-caption {
    font: 14px/1.86 'EffraMedium', sans-serif;
    letter-spacing: .5px;
    color: #5cf3ec;
    padding-top: 27px;
    display: flex;
    flex-direction: column;
  }

  &__content-title {
    font: 52px/1.35 'Athelas', sans-serif;
    color: #fff;
    margin-bottom: 36px;

    @media (max-width: 768px) {
      font-size: 42px;
    }

    @media (max-width: 576px) {
      font-size: 26px;
    }
  }

  &__content-subtitle {
    position: relative;
    font: 32px/1.3 'Athelas', sans-serif;
    color: #fff;
    margin-bottom: 20px;
    padding-left: 82px;

    @media (max-width: 576px) {
      font-size: 24px;
      padding-left: 60px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 17px;
      width: 50px;
      height: 1px;
      background-color: rgba(255, 255, 255, .3);
    }
  }

  &__content-text {
    font: 14px/1.86 'Effra', sans-serif;
    letter-spacing: .5px;
    color: #94a3d1;
    padding-left: 82px;

    @media (max-width: 576px) {
      padding-left: 0px;
    }

    &--grid-3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }

    &--p0 {
      padding: 0;
    }

    p {
      &:not(:last-of-type) {
        margin-bottom: 14px;
      }
    }
  }

  &__list {
    padding-left: 25px;
    font-size: 24px;
    line-height: 1.58;
    color: #94a3d1;

    @media (max-width: 576px) {
      font-size: 18px;
    }

    li {
      position: relative;
      list-style-type: disc;

      &:not(:last-child) {
        margin-bottom: 23px;
      }
    }
  }

  &__owner {
    display: none;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    letter-spacing: .5px;

    @media (max-width: 991px) {
      display: block;
      padding: 40px 0 20px;
      margin: 0 auto;
    }

    &--mobile-hidden {
      display: block;

      @media (max-width: 991px) {
        // display: none;
      }
    }

    svg {
      width: 100%;
      display: block;
      margin-bottom: 11px;
      max-width: 180px;
      height: auto;
      opacity: 0;

      @media (max-width: 991px) {
        width: 250px;
        max-width: 100%;
      }
    }

    &--typo svg {
      width: 110%;
      max-width: 210px;

      @media (max-width: 991px) {
        width: 250px;
        max-width: 100%;
      }
    }
  }

  &__owner-typography {
    margin-bottom: 75px !important;

    @media (max-width: 991px) {
      margin-bottom: 0px !important;
      margin-top: 57px !important;
      width: 250px;
    }
  }

  &__owner-name {
    font: 18px/22px 'Effra', sans-serif;
    color: #fff;
  }

  &__owner-position {
    line-height: 22px;
    color: #5c65a1;
  }
}

.list-block {
  &__title {
    display: block;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 31px;
    font: 16px/1.5 'EffraMedium', sans-serif;
    letter-spacing: .5px;

    @media (max-width: 576px) {
      margin-top: 26px;
      margin-bottom: 14px;
    }
  }

  &__list {
    padding-left: 15px;
    list-style-type: disc;
    font: 16px/1.5 'Effra', sans-serif;
    letter-spacing: 0.25px;
  }
}

.technologies-list {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 50px;

  @media (max-width: 2010px) {
    grid-column-gap: 30px;
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 50px;
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;
  }

  &__item {
    position: relative;
    z-index: 10;
    width: 103px;
    height: 103px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 2010px) {
      width: 70px;
      height: 70px;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to bottom, #485184 31%, #313659 87%);
      transform: rotate(45deg);
      border-radius: 10px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.our-clients {
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }

  &__item {
    display: flex;

    &:first-child {
      transform: translateY(-5px);
    }

    &:not(:last-child) {
      margin-right: 40px;

      @media (max-width: 1300px) {
        margin-right: 20px;
      }
    }
  }
}