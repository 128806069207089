.image-preloader {
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(169deg, #070e3c, #070617 65%);
    transition: all .5s;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1001;
    left: 50%;
    top: 50%;
    webkit-animation-duration: 0.75s;
    -moz-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: rotate-forever;
    -moz-animation-name: rotate-forever;
    animation-name: rotate-forever;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    animation-timing-function: linear;
    height: 30px;
    width: 30px;
    border: 3px solid #ffffff;
    border-right-color: transparent;
    border-radius: 50%;
    display: inline-block;
    margin-top: -15px;
    margin-left: -15px;
    transition: all .5s;
  }

  &--loading {
    &:before {
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }

}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}