.slider {
  position: relative;
  overflow: hidden;

  &__bg {
    height: 100vh;
    overflow: hidden;
  }

  &__bg-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 100vw);
  }

  &__bg-item {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    max-width: 1570px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;

    &--pagination {
      display: grid;
      grid-template-columns: 329px 1fr;
      grid-column-gap: 43px;
      margin-bottom: 70px;
      align-items: end;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
  }

  &__pagination-container {
    position: relative;
  }

  &__pagination {
    padding: 6px 0;
    display: grid;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, .3);
    border-width: 2px 0 2px 0;
    position: relative;
    z-index: 100;

    &--4 {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 35px;
    }
  }

  &__pagination-line {
    position: absolute;
    width: 65px;
    height: 2px;
    background: #fff;
    bottom: 6px;
    left: 0;
  }

  &__pagination-item {
    color: #fff;
    opacity: .5;
    font-size: 14px;
    padding: 29px 10px;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all .3s;

    &:hover:not(.active) {
      transform: translateY(-5px);
      opacity: .4;
    }

    &.active {
      font-weight: bold;
      opacity: 1;
    }
  }

  &__projects {
    position: relative;
    transform: translateY(50%);
  }

  &__project-name {
    font: 14px/1 'EffraLight', sans-serif;
    color: #fff;
    opacity: 0;
    top: 0;
    left: 0;

    &:first-child {
      opacity: 1;
    }
  }

  &__titles {
    max-width: 830px;
    padding: 0 15px;
    position: relative;
  }

  &__title {
    color: #fff;
    font: 54px/1 'Athelas', sans-serif;
    line-height: 1.2;
    position: absolute;
    top: 0;
  }

  &__actions {
    position: relative;

    .btn__container {
      top: 0;
      left: 0;
    }
  }
}

.letter,
.line {
  display: inline-block;
}
