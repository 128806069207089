.content {
  position: relative;
  background-color: #0d0f1a;
  overflow: hidden;

  &__bg {
    padding: 60px 0 75px;

    @media (max-width: 768px) {
      padding: 30px 0 35px;
    }
    
    &--p0 {
      padding: 0!important;
    }
  }

  &__container {
    max-width: 1280px;
    padding: 0 20px 100px;
    margin: 0 auto;
    text-align: center;

    &--p0 {
      padding: 0 20px!important;
    }

    img {
      max-width: 100%;
    }
    
    &--fullwidth {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__title {
    margin: 0 0 50px;
    font: 36px/1.2 'Athelas';
    color: rgba(255,255,255, .7);
    text-align: center;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  &__subtitle {
    display: inline-block;
    max-width: 713px;
    font: 16px/24px 'Effra';
    color: #5a76a3;
    margin-bottom: 40px;
  }

  &__avaliable-on {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px 160px;
    text-decoration: none;

    @media (max-width: 576px) {
      padding: 50px 20px 100px;
    }

    .image-preloader {
      width: auto;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__avaliable-on-title {
    text-transform: uppercase;
    color: #7982a5;
    font: 24px/1.5 'Effra', sans-serif;
    letter-spacing: 1.5px;
    margin-bottom: 60px;
  }
}