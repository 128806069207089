.btn {
  display: inline-block;
  position: relative;
  z-index: 10;
  text-decoration: none;
  font: 14px/1.2 'EffraMedium', sans-serif;
  letter-spacing: .5px;
  $btn: &;

  &--move {
    color: #5cf3ec;
    padding: 11px 5px;
    overflow: hidden;

    //&:hover {
    //  #{$btn}__line:before {
    //    transform: translate(0, 0)!important;
    //  }
    //}
  }

  &__line {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    height: 2px;
    transform: translateX(0);
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #5cf3ec;
      //animation: lineMove 5s infinite ease-in-out;
      animation-delay: 5s;
      transition: all .7s;
    }
  }

  &--icon {
    display: flex;
  }

  &__container {
    display: block;

    &--contact {
      padding: 70px 50px 40px;
    }

    &--coming-soon {
      padding: 50px 0 36px;
    }

    &--social {
      padding: 20px 10px;
    }
  }
}

@keyframes lineMove {
  0% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(0);
  }

  60% {
    transform: translate(200%, 0);
  }

  70% {
    transform: translate(-200%, -5px);
  }

  75% {
    transform: translate(-200%, -5px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.btn-submit {
  display: inline-block;
  padding: 12px;
  min-width: 221px;
  font: 14px/1 'EffraMedium';
  color: #fff;
  letter-spacing: .5px;
  border-radius: 4px;
  background: linear-gradient(90deg, #328f8a, #5ee5df);
  border: none;
  outline: none;
  cursor: pointer;
}