.coming-soon {
  height: 100vh;
  background-image: linear-gradient(169deg, #070e3c, #070617 39%);
  display: flex;
  align-items: center;

  &__container {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__logo-subtitle {
    font: 24px/1 'EffraMedium', sans-serif;
    color: #5cf3ec;
    letter-spacing: 1.33px;
    margin-bottom: 36px;
  }

  &__title {
    font: 32px/1 'Athelas', sans-serif;
    color: #fff;
    margin-bottom: 20px;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn--icon {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
}