.ideas {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 170px 0;
  $ideas: &;

  @media (max-width: 768px) {
    padding: 130px 0 80px;
  }

  &__label {
    display: none;
    font: 32px/1 'Athelas', sans-serif;
    color: rgba(#6778ff, .7);
    margin-bottom: 10px;
  }

  &__container {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;

    @media (max-width: 576px) {
      padding: 0 20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  a {
    display: inline-block;
    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: 35px;
    }

    &:hover {
      #{$ideas}__item {
        transform: scale(1.05);
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    transition: all .5s;
    text-align: center;
  }

  &__item-title {
    display: inline-block;
    font: 42px/1.2 'EffraMedium', sans-serif;
    letter-spacing: .5px;
    color: #fff;
    padding: 12px 75px 18px;
    background-color: #171a2a;
    border-radius: 3px;

    @media (max-width: 768px) {
      font-size: 32px;
      padding: 12px 30px 18px;
    }

    @media (max-width: 576px) {
      font-size: 24px;
    }
  }

  &__item-label {
    font: 24px/1.2 'EffraMedium', sans-serif;
    color: #5cf3ec;
    opacity: .3;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__item-subtitle {
    font: 18px/1.2 'Effra', sans-serif;
    color: rgba(255,255,255, .3);
    margin-top: 5px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}