.contact {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 170px;
  background: linear-gradient(176deg, rgba(8,15,57, .3), rgba(7,6,23, .3) 49%), url("/img/agency-bg.png") no-repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;

  @media (max-width: 576px) {
    padding-top: 110px;
    //background: linear-gradient(to bottom,  rgba(#1f2235, .5), rgba(#1f2235, .5)), linear-gradient(186deg, #323a6e 98%, rgba(0, 0, 0, 0) 66%)
    background: linear-gradient(186deg, rgba(#323a6e, .5) 100%, rgba(0, 0, 0, 0) 66%), linear-gradient(to bottom, #1f2235, #1f2235);
  }

  &__container {
    max-width: 1010px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    &--m-auto {
      margin: auto;
    }

    &--links {
      padding-top: 20px;
      padding-bottom: 70px;
      display: flex;
      justify-content: flex-end;

      @media (max-width: 576px) {
        padding-bottom: 24px;
      }
    }
  }

  &__title {
    font: 54px/1 'Athelas', sans-serif;
    color: #fff;
    margin-bottom: 5px;

    @media (max-width: 576px) {
      font-size: 32px;
    }
  }

  &__subtitle {
    font: 14px/1.8 'EffraRegular', sans-serif;
    color: #fff;
    letter-spacing: .5px;
    max-width: 468px;
    margin-bottom: 11px;

    a {
      text-decoration: none;
      color: #5cf3ec;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    margin-bottom: 32px;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
      margin-bottom: 50px;
    }
  }

  &__group {
    display: grid;
    grid-row-gap: 20px;
  }

  &__row {
    padding: 10px;
    background-color: rgba(74, 86, 100, .2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    label {
      display: block;
      font: 12px/1 'EffraLight', sans-serif;
      color: #869d9d;
      text-transform: uppercase;
      margin-bottom: 6px;

      &.error {
        color: red;
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 12px;
      background: rgba(255,255,255, .1);
      border-radius: 3px;
      border: none;
      outline: none;
      font: 14px/1 'Effra';
      color: #fff;
      letter-spacing: .5px;

      &.error {
        border: 1px solid red
      }

      &::placeholder {
        color: #d1dce6;
      }
    }

    textarea {
      max-width: 100%;
      height: 100%;
      resize: none;
      
      @media (max-width: 576px) {
        min-height: 219px;
      }
    }
  }

  .btn-submit {
    position: relative;
    display: block;
    margin: 0 auto;

    &:disabled {
      opacity: .8;
      cursor: wait;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 20px;
        margin: -10px 0 0 -10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid rgba(255,255,255,.3);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
      }
    }
  }
  
  &__success {
    font: 14px/1 'Effra';
    color: #fff;
    text-align: center;
    display: block;
    margin-bottom: 15px;
  }
}